.loading-logo {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
    animation: pulse 2s infinite;
}

.loading-screen {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    width: 100%;
    height: inherit;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }

h2 {
    font-size: 1em;
    font-weight: 500;
    padding: 5px;
}

@media (max-width: 768px) {
    .loading-logo {
        width: 80px;
    }

    .loading-screen {
        text-align: center;
        padding: 20px;
        align-items: center;
        justify-content: center;
    }
}
  