.results-confirmation-container {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.tables-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}



.results-summary .align-right {
  text-align: right;
}

.results-summary .align-center {
  text-align: center;
}



.results-detailed .overflow-auto {
  overflow: auto;
}

.results-detailed .align-center {
  text-align: center;
}

.image-name-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.image-preview {
  width: 20px;
  height: 20px;
}

.action-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}

.back-button-confirmation {
  width: 100px;
  height: 40px;
  color: black;
  border: none;
  border-radius: 5px;
}

.button-group-confirmation {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
}

