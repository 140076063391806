.about-container {
    margin: 3% 7%;
    text-align: center;
    max-width: 100vw;
}

.group-photo {
    width: 70%;
    height: auto;
    margin: auto;
    border-radius: 10px;
}

.about-title {
    text-align: center;
    font-size: 2em;
    font-weight: 600;
    margin: 20px;
}

.about-description {
    text-align: center;
}