.products-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.section-content-center {
  text-align: center;
  margin-bottom: 50px;
}

.product-options {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}

.product-option {
  cursor: pointer;
}

.product-option a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.product-icon {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 10px;
}

.product-icon-title {
  text-align: center;
  /* Optionally, you can set a fixed height if you want all titles to have the same height */
  /* height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Rest of the CSS remains the same */

.products-section {
  margin-bottom: 50px;
}

.product-content-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}

.product-img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-img img {
  max-width: 100%;
  height: auto;
}

.section-content {
  flex: 1;
}

.section-header {
  margin-bottom: 20px;
}

/* Alternating layout */
.products-section.text-first .product-content-wrapper {
  flex-direction: row-reverse;
}

@media (max-width: 768px) {
  .product-content-wrapper {
      flex-direction: column !important;
  }
  
  .products-section.text-first .product-content-wrapper {
      flex-direction: column !important;
  }
}