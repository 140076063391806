.pricing-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
  }
  
  .header-container {
    text-align: center;
    margin-bottom: 48px;
  }
  
  .main-title {
    font-weight: 700;
    margin-bottom: 16px;
  }
  
  .sub-title {
    color: #666666;
    font-size: 1rem;
  }
  
  .pricing-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .pricing-card.highlighted {
    border: 2px solid #00bcd4;
    background-color: #f5f5f5;
  }
  
  .card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px !important;
  }
  
  .card-title {
    font-weight: 700;
    margin-bottom: 8px;
    text-align: left;
  }
  
  .card-description {
    color: #666666;
    margin: 10px 0;
    text-align: left;
    min-height: 90px;
  }
  
  .card-button {
    text-transform: none;
    border-radius: 24px;
    padding: 8px 16px;
    font-weight: 600;
    background-color: #00bcd4;
    color: white;
    align-self: flex-start;
    margin-bottom: 20px;
  }
  
  .card-button:hover {
    background-color: #00a0b4;
  }
  
  .feature-list {
    list-style: none;
    padding: 0;
    margin: 10px 0;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .feature-item svg {
    color: #00bcd4;
    margin-right: 8px;
  }
  
  .feature-text {
    text-align: left;
  }
