.sidebar-text {
  font-family: 'Avenir Next Cyr';
  width: 100%;
}

.sidebar-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.notification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  font-family: 'Avenir Next Cyr';
  background-color: #f8f3f3;
  color: black;
  font-size: 0.9rem;
  padding: 10px;
}

.accept-button {
  background-color: #20b3ce;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.decline-button {
  background-color: #f36e4f;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: 'Avenir Next Cyr';
  font-size: 0.9rem;
  gap: 10px;
  margin-top: 5px;
}

.sidebar-item {
  align-items: center;
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
  width: 95%;
  align-self: flex-end;
  text-align: center;
  font-family: 'Avenir Next Cyr';
}

.dropdownIconColor {
  color: #20b3ce;
}

.listItem {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  font-family: 'Avenir Next Cyr';
}

.selectedSideBarItem {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
  font-family: 'Avenir Next Cyr';
  background-color: #20b3ce;
  color: white;
}

.notification-icon {
  background-color: #86d7e6;
  color: white;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  margin-right: 5px;
}

@media (max-width: 1024px) {
  .sidebar-container-test {
    display: none;
  }
}
