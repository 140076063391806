/* MultiEmailField.css */

.email-field-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.react-multi-email {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  background-color: #fff;
  transition: border-color 0.2s;
}

.react-multi-email:focus-within {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.react-multi-email input {
  border: none;
  outline: none;
  font-size: 14px;
  margin: 4px;
  padding: 2px;
}

.react-multi-email [data-tag] {
  display: inline-block;
  background-color: #f0f0f0;
  border-radius: 16px;
  padding: 2px 8px;
  margin: 2px;
  font-size: 12px;
}

.react-multi-email [data-tag-handle] {
  cursor: pointer;
  margin-left: 4px;
  color: #999;
}

.react-multi-email [data-tag-handle]:hover {
  color: #666;
}

/* Placeholder style */
.react-multi-email input::placeholder {
  color: #999;
}

.field-label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #333;
}
