.contact-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    text-align: left;
    padding: 20px 0px 50px 0px;
    font-family: inherit;
    margin: 3% 7%;
    gap: 7em;
}

.contact-text-section {
    padding-right: 20px;
}

.input-field-container {
    margin-top: 20px;
}

.contact-form-container {
    grid-column: span 1;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    width: 100%;
    overflow: hidden;
}

.contact-section-title {
    margin-bottom: 15px;
    font-weight: 550;
    font-size: xx-large;
    color: #20b3ce;
}

.contact-subtitle {
    margin-bottom: 50px;
    color: #666666;
    font-size: medium;
}

.book-demo-button {
    background-color: #f36e4f;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    width: 70%;
}

.submit-button {
    background-color: #f36e4f;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    width: 50%;
}

.submit-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}

.book-demo-subtitle {
    margin-top: 10px;
    font-weight: normal;
    font-size: small;
    color: #666666;
}

.email-link {
    color: #f36e4f;
}


@media (max-width: 1000px) {
    .contact-section {
        justify-content: space-between;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }

    .contact-section-title {
        margin-bottom: 15px;
        font-weight: 550;
        font-size: xx-large;
        color: #20b3ce;
    }

    .contact-form-container {
        width: 100%;
    }
}