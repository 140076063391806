.gel-electrophoresis-editor__toolbar {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    padding: 10px;
    max-height: calc(100% - 20px);
    overflow-y: auto;
    transition: all 0.3s ease;
    z-index: 1000;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
  }
  
  .gel-electrophoresis-editor__toolbar.collapsed {
    width: 40px;
    height: 40px;
    overflow: hidden;
    padding: 0;
  }
  
  .gel-electrophoresis-editor__toolbar.collapsed > *:not(.gel-electrophoresis-editor__toolbar-header) {
    display: none;
  }
  
  .gel-electrophoresis-editor__toolbar.collapsed .gel-electrophoresis-editor__toolbar-header {
    margin-bottom: 0;
  }
  
  .gel-electrophoresis-editor__toolbar.collapsed .gel-electrophoresis-editor__toolbar-pin {
    display: none;
  }
  
  .gel-electrophoresis-editor__toolbar:not(.pinned):not(:hover):not(.collapsed) {
    opacity: 0;
    pointer-events: none;
  }
  
  .gel-electrophoresis-editor__toolbar-header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .gel-electrophoresis-editor__toolbar-toggle,
  .gel-electrophoresis-editor__toolbar-pin {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
  }
  
  .gel-electrophoresis-editor__toolbar-pin.pinned {
    color: #23c9e8;
    transform: rotate(90deg);
  }
  
  .gel-electrophoresis-editor__toolbar .MuiIconButton-root {
    color: white;
  }
  
  .gel-electrophoresis-editor__toolbar .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .gel-electrophoresis-editor__toolbar .MuiIconButton-root.active {
    color: #23c9e8;
  }
  