.project-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 50px;
  background-color: #f5f5f5;
}

.edit-icon {
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.edit-icon:hover {
  color: #f36e4f;
}


@media (max-width: 768px) {
  .project-page-wrapper {
    padding: 40px;
  }
}
