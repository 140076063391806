.add-box-dialog {
  min-width: 300px;
}

.add-box-dialog__title {
  background-color: #f5f5f5;
  color: #333;
  padding: 16px;
  font-size: 1.2rem;
  font-weight: 600;
}

.add-box-dialog__content {
  padding: 24px 24px 16px;
}

.add-box-dialog__color-swatch {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 12px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.add-box-cursor {
  cursor: crosshair;
}

.gel-electrophoresis-editor__instructions {
  background-color: #e8f4fd;
  border-left: 4px solid #2196f3;
  padding: 12px 16px;
  margin-bottom: 10px;
  line-height: 1.6;
  width: 100%;
}





@media (max-width: 768px) {
  .gel-electrophoresis-editor__container {
    flex-direction: column;
    height: auto;
  }

  .gel-electrophoresis-editor__image-container {
    height: 50vh;
  }

  .gel-electrophoresis-editor__table-container {
    width: 100%;
    height: 40vh;
    overflow-y: auto;
  }
}